import * as React from 'react';
import { useRouter } from 'next/router';
import { useAuth } from '../../../utils/auth';
import { BottomNavigationBar } from 'ui';

interface propTypes {
  children: React.ReactNode;
}

export const Container: React.FC<propTypes> = ({ children }) => {
  const router = useRouter();
  const NavBarRoutes = [
    '/login',
    '/login/email',
    '/signup',
    '/signup/email',
    '/usage',
    '/subscription',
    '/verify-email',
    'profile/edit',
    'chat/[id]',
  ];
  const { userProfile, loading, user, authenticated } = useAuth();

  return (
    <React.Fragment>
      <div className="xs:px-8 mx-auto max-w-[600px] px-4 pb-[46px]">
        {children}
      </div>
      {!NavBarRoutes.includes(router.pathname) && authenticated && (
        <BottomNavigationBar
          user={user}
          userProfile={userProfile}
          loading={loading}
        />
      )}
    </React.Fragment>
  );
};
