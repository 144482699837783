import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { Box, useDisclosure } from '@chakra-ui/react';
import {
  AfterLoginDrawerItems,
  BeforeLoginDrawerItems,
  NavigationDrawer,
  TopNav,
} from 'ui';
import { User } from 'firebase/auth';
import { useAuth } from '../../../utils/auth';

type HeaderProps = {
  logOut: () => void;
  authenticated?: boolean | null;
  user: User | null;
  setSelectedTabIndex: Dispatch<SetStateAction<number>>;
  setShowFilters: Dispatch<SetStateAction<boolean>>;
  subscription: boolean;
};

export const Header: React.FC<HeaderProps> = ({
  logOut,
  authenticated,
  user,
  setSelectedTabIndex,
  setShowFilters,
  subscription,
}: HeaderProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { redirectedFromNavDrawer, setRedirectedFromNavDrawer } = useAuth();

  useEffect(() => {
    redirectedFromNavDrawer && onOpen();
    setRedirectedFromNavDrawer(false);
  }, []);

  return (
    <>
      <Box className="bg-gray-3 fixed z-30 h-[46px] w-full">
        <NavigationDrawer
          isOpen={isOpen}
          onClose={onClose}
          onLogout={logOut}
          drawerItems={
            authenticated ? AfterLoginDrawerItems : BeforeLoginDrawerItems
          }
          setRedirectedFromNavDrawer={setRedirectedFromNavDrawer}
          authenticated={!!authenticated}
          user={user}
          subscription={subscription}
          setSelectedTabIndex={setSelectedTabIndex}
        />
        <TopNav onOpen={onOpen} setShowFilters={setShowFilters} />
      </Box>

      {/* <div className="mb-[16px] h-[46px]" /> */}
      <div className="h-[46px]" />
    </>
  );
};
