import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import React, { useEffect, useState } from 'react';
import {
  Panel,
  NoResultsFound,
  MetaTags,
  EventList,
  FavoriteBeforeAuth,
  NoFavorite,
} from 'ui';
import { QueryClient, useInfiniteQuery } from 'react-query';
import { getFavoritesEvents, getLiveEvents, getUpcomingEvents } from 'services';
import { toastError } from 'toast-schema';
import { useToast } from '@chakra-ui/react';
import { GetServerSideProps } from 'next';
import { useTranslation } from 'next-i18next';
import { useAuth } from '../utils/auth';
import dynamic from 'next/dynamic';
import { Container, HomeBanner } from '../components';
import { useRouter } from 'next/router';
import format from 'date-fns/format';
import { removeEmpty } from 'helpers';
import { IEventResponse } from 'models';

const DynamicHeader = dynamic(
  () => import('../components/organisms/Header').then(m => m.Header),
  { ssr: false },
);
const DynamicEventSearch = dynamic(
  () =>
    import('ui/components/molecules/EventSearchComponent').then(
      m => m.EventSearchComponent,
    ),
  { ssr: false },
);

const EventListPage = ({ liveEvents }: { liveEvents: IEventResponse }) => {
  const toast = useToast();
  const { t } = useTranslation();
  const [showFilters, setShowFilters] = useState<boolean>(false);
  const {
    selectedTabIndex,
    setSelectedTabIndex,
    logOut,
    authenticated,
    user,
    userProfile,
    setRedirectedFromNavDrawer,
    initializing,
  } = useAuth();

  const [startDate, setStartDate] = useState<Date | null>(null);
  const [selectedGenre, setSelectedGenre] = useState<string[]>([]);
  const [selectedLocation, setSelectedLocation] = useState<string | string[]>(
    '',
  );
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const router = useRouter();
  const {
    keyword,
    location,
    start_date,
    end_date,
  }: {
    keyword?: string;
    location?: string | string[] | null;
    start_date?: string;
    end_date?: string;
  } = router.query;

  const genre: string[] =
    router.query.genre && typeof router.query.genre === 'string'
      ? [router.query.genre]
      : (router.query.genre as string[]);

  //get query params from url and set state values
  useEffect(() => {
    if (keyword || genre || location || start_date || end_date) {
      setShowFilters(true);
      keyword && setSearchQuery(keyword);
      location && setSelectedLocation(location);
      genre && setSelectedGenre(genre);
      start_date && setStartDate(new Date(start_date));
      end_date && setEndDate(new Date(end_date));
    }
  }, [keyword, genre?.length, location, start_date, end_date]);

  //set queries in URL
  useEffect(() => {
    router.push(
      {
        pathname: '/',
        query: removeEmpty({
          keyword: searchQuery,
          genre: selectedGenre,
          location: selectedLocation,
          start_date: startDate && format(startDate, 'yyyy-MM-dd'),
          end_date: endDate && format(endDate, 'yyyy-MM-dd'),
        }),
      },
      undefined,
      { shallow: true },
    );
  }, [searchQuery, selectedGenre, selectedLocation, startDate, endDate]);

  const { data, hasNextPage, fetchNextPage, isFetchingNextPage, isLoading } =
    useInfiniteQuery(
      [
        'liveEvents',
        searchQuery,
        startDate,
        endDate,
        selectedGenre,
        selectedLocation,
        authenticated,
      ],
      ({ pageParam = 1 }) => {
        if (pageParam === null) {
          pageParam = 1;
        }
        return getLiveEvents(
          pageParam,
          searchQuery!,
          startDate!,
          endDate!,
          selectedGenre!,
          selectedLocation!,
        );
      },
      {
        getNextPageParam: lastPage => {
          if (lastPage?.pagination?.has_next) {
            return lastPage.pagination.page + 1;
          }
        },
        retry: false,
        enabled: selectedTabIndex == 0,
        keepPreviousData: true,
        initialData: liveEvents
          ? { pages: [liveEvents], pageParams: [undefined] }
          : undefined,
        onError: () => {
          if (!toast.isActive('fetch')) {
            toastError({
              toastId: 'fetch',
              toast,
              description: t('Error occurred'),
            });
          }
        },
      },
    );
  const {
    data: upComingData,
    hasNextPage: upcomingHasNextPage,
    fetchNextPage: upcomingFetchNextPage,
    isFetchingNextPage: upcomingIsFetchingNext,
    isLoading: upcomingIsLoading,
  } = useInfiniteQuery(
    [
      'upcomingEvents',
      searchQuery,
      startDate,
      endDate,
      selectedGenre,
      selectedLocation,
      authenticated,
    ],
    ({ pageParam = 1 }) => {
      if (pageParam === null) {
        pageParam = 1;
      }
      return getUpcomingEvents(
        pageParam,
        searchQuery!,
        startDate!,
        endDate!,
        selectedGenre!,
        selectedLocation!,
      );
    },
    {
      getNextPageParam: lastPage => {
        if (lastPage.pagination.has_next) {
          return lastPage.pagination.page + 1;
        }
      },
      retry: false,
      enabled: selectedTabIndex == 1,
      keepPreviousData: true,
      onError: () => {
        if (!toast.isActive('fetch')) {
          toastError({
            toastId: 'fetch',
            toast,
            description: t('Error occurred'),
          });
        }
      },
    },
  );
  const {
    data: favoriteData,
    hasNextPage: favoriteHasNextPage,
    fetchNextPage: favoriteFetchNextPage,
    isFetchingNextPage: favoriteIsFetchingNext,
    isLoading: favoriteIsLoading,
  } = useInfiniteQuery(
    [
      'favoriteEvents',
      searchQuery,
      startDate,
      endDate,
      selectedGenre,
      selectedLocation,
      authenticated,
    ],
    ({ pageParam = 1 }) => {
      if (pageParam === null) {
        pageParam = 1;
      }
      return getFavoritesEvents(
        pageParam,
        searchQuery!,
        startDate!,
        endDate!,
        selectedGenre!,
        selectedLocation!,
      );
    },
    {
      getNextPageParam: lastPage => {
        if (lastPage.pagination.has_next) {
          return lastPage.pagination.page + 1;
        }
      },
      retry: false,
      enabled: selectedTabIndex == 2,
      onError: () => {
        if (!toast.isActive('fetch')) {
          toastError({
            toastId: 'fetch',
            toast,
            description: t('Error occurred'),
          });
        }
      },
    },
  );
  const panels = [
    {
      label: 'Live Events',
      panel: (
        <>
          {data?.pages && data?.pages[0]?.data?.length === 0 && (
            <NoResultsFound text="No events available!" />
          )}

          <Panel
            data={data}
            isFetchingNext={isFetchingNextPage}
            fetchNext={fetchNextPage}
            hasNext={hasNextPage}
            isLoading={isLoading}
          />
        </>
      ),
    },
    {
      label: 'Upcoming',
      panel: (
        <>
          {upComingData?.pages && upComingData?.pages[0]?.data?.length === 0 && (
            <NoResultsFound text="No events available!" />
          )}

          <Panel
            data={upComingData}
            isFetchingNext={upcomingIsFetchingNext}
            fetchNext={upcomingFetchNextPage}
            hasNext={upcomingHasNextPage}
            isLoading={upcomingIsLoading}
          />
        </>
      ),
    },
    {
      label: 'My Favorite',
      id: 'my-favorite',
      panel: (
        <>
          {initializing || favoriteIsLoading ? <Panel
            data={favoriteData}
            isFetchingNext={favoriteIsFetchingNext}
            fetchNext={favoriteFetchNextPage}
            hasNext={favoriteHasNextPage}
            isLoading={true}
          /> : !user ? (
            <FavoriteBeforeAuth
              setRedirectedFromNavDrawer={setRedirectedFromNavDrawer}
            />
          ) : (
            <>
              {favoriteData?.pages?.[0]?.data?.length === 0 && (
                <NoFavorite setSelectedTabIndex={setSelectedTabIndex} />
              )}
              <Panel
                data={favoriteData}
                isFetchingNext={favoriteIsFetchingNext}
                fetchNext={favoriteFetchNextPage}
                hasNext={favoriteHasNextPage}
                isLoading={favoriteIsLoading}
              />
            </>
          )}
        </>
      ),
    },
  ];

  const keywords = `
                    meld,
                    festival buddy finder,
                    concert partner,
                    music festival friend,
                    フェスティバルバディファインダー,
                    音楽フェスティバル仲間探し,
                    音楽フェスティバルパートナー探し
                  `;

  return (
    <>
      <MetaTags
        title={t('live_events')}
        description={`${t('About MELD 3')} \n ${t('About MELD 4')}`}
        keywords={keywords}
      />
       <DynamicHeader
            subscription={!!userProfile?.subscription}
            logOut={logOut}
            authenticated={authenticated}
            user={user ? user : null}
            setSelectedTabIndex={setSelectedTabIndex}
            setShowFilters={setShowFilters}
          />
      {/* {!showFilters ? (
        <div className="px-0">
          <DynamicHeader
            subscription={!!userProfile?.subscription}
            logOut={logOut}
            authenticated={authenticated}
            user={user ? user : null}
            setSelectedTabIndex={setSelectedTabIndex}
            setShowFilters={setShowFilters}
          />
        </div>
      ) : (
        <DynamicEventSearch
          setShowFilters={setShowFilters}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          setSearchQuery={setSearchQuery}
          startDate={startDate}
          endDate={endDate}
          setSelectedGenre={setSelectedGenre}
          setSelectedLocation={setSelectedLocation}
          selectedLocation={selectedLocation}
          searchQuery={searchQuery}
          selectedGenre={selectedGenre}
        />
      )} */}


      <HomeBanner />
      {/* --- new code --- */}
      <DynamicEventSearch
          setShowFilters={setShowFilters}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          setSearchQuery={setSearchQuery}
          startDate={startDate}
          endDate={endDate}
          setSelectedGenre={setSelectedGenre}
          setSelectedLocation={setSelectedLocation}
          selectedLocation={selectedLocation}
          searchQuery={searchQuery}
          selectedGenre={selectedGenre}
        />
      <Container>
        <div className={`${showFilters ? '' : 'mt-2'}`}>
          <EventList
            data={panels}
            selectedIndex={selectedTabIndex}
            setSelectedTabIndex={setSelectedTabIndex}
            showFilters={showFilters}
          />
        </div>
      </Container>
    </>
  );
};

export default EventListPage;

export const getServerSideProps: GetServerSideProps = async ({
  query,
  locale,
}) => {
  const qc = new QueryClient();

  const {
    keyword,
    location,
    start_date,
    end_date,
  }: {
    keyword?: string;
    location?: string;
    start_date?: string;
    end_date?: string;
  } = query;
  const genre: string[] =
    query.genre && typeof query.genre === 'string'
      ? [query.genre]
      : (query.genre as string[]);
  const sDate = start_date ? new Date(start_date as string) : undefined;
  const eDate = end_date ? new Date(end_date as string) : undefined;

  const translations = await serverSideTranslations(locale || 'ja', ['common']);
  try {
    const liveEvents = await qc.fetchQuery(
      'events-live',
      () => getLiveEvents(1, keyword, sDate, eDate, genre, location),
      { staleTime: 10 },
    );

    return {
      props: {
        ...translations,
        liveEvents,
      },
    };
  } catch (e) {
    return {
      props: {
        ...translations,
      },
    };
  }
};
