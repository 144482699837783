import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import Link from 'next/link';
import * as React from 'react';
import { useAuth } from '../../../utils/auth';
import { useRouter } from 'next/router';

interface ButtonRegistryProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {}
const ButtonRegistry = ({ className, ...props }: ButtonRegistryProps) => {
  const { t } = useTranslation();
  return (
    <button
      className={`${className} ring-orange-7 group relative inline-flex items-center justify-center
      gap-x-2 rounded-[2rem] bg-[#E28937] py-3 px-5 tracking-wider text-white shadow-md ring-offset-1 
      transition-all duration-200 hover:brightness-90 focus:ring-2 active:scale-90
      `}
      {...props}
    >
      {t('無料会員登録はこちら')}
      <Image
        src="/assets/svg/arrow-right.svg"
        height={24}
        width={24}
        alt="arrow right icon"
        className="animate-left-to-current"
      />
    </button>
  );
};

function HomeBanner() {
  const { t } = useTranslation();
  const router = useRouter();
  const { authenticated, loading } = useAuth();
  return (
    <div className="flex h-[25rem] w-full items-center justify-center bg-white px-2.5">
      <div className="w-full max-w-[765px] rounded-[1.25rem] border-[2px] border-[#E28937] px-3 py-4 text-center text-black lg:px-[3.75rem] lg:pt-10 lg:pb-6">
        <h4 className="p-0.5 text-lg font-semibold lg:p-0">
          {t('同じ趣味を持つ誰かとライブに行って感動を共有しませんか？')}
        </h4>
        <p className="mt-3 text-sm leading-6">
          {t('MELDは音楽イベントのマッチングサービスです。')} <br />
          {t(
            '1人だと行くのを躊躇っていたライブに同じ気持ちを持つ人とマッチングして音楽をもっと楽しもう！',
          )}
        </p>
        {!authenticated && !loading && (
          <ButtonRegistry
            className="mt-8"
            onClick={() => router.push('/signup')}
          />
        )}
        <Link href="/about-meld" passHref legacyBehavior>
          <a className="block mx-auto mt-6 text-sm text-[#E28937] transition-all text-link w-fit hover:opacity-90">
            {t('MELDについて')}
          </a>
        </Link>
      </div>
    </div>
  );
}

export default HomeBanner;
